import { createSlice } from "@reduxjs/toolkit";

const integrationsSlice = createSlice({
    name: 'integrations',
    initialState: {
        trigger: false,
        channels: [],
        channelId: '',
        channelValue: '',
    },
    reducers: {
        setTrigger: (state, action) => {
            state.trigger = action.payload.trigger;
        },
        setChannels: (state, action) => {
            state.channels = action.payload.channels;
        },
        setChannelId: (state, action) => {
            state.channelId = action.payload.channelId;
        },
        setChannelValue: (state, action) => {
            state.channelValue = action.payload.channelValue;
        },
    }
})

export const { setTrigger, setChannels, setChannelId, setChannelValue } = integrationsSlice.actions;

const IntegrationsReducer = integrationsSlice.reducer;
export default IntegrationsReducer;