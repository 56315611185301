import React from 'react'
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { ApiRequest } from '../../api';
import { setChannelName, setLeadChannelsTrigger } from '../../redux/features/leadChannels.slice';
import { Breadcrumb, Card, Col, Form, FormGroup, Row } from 'react-bootstrap';

function LeadChannelsAdd() {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { channelName , leadChannelsTrigger } = useSelector(state => state.leadChannels);

    /*====================== SWEETALERT START ======================*/
    const Toast = Swal.mixin({
        toast: true,
        position: 'bottom-end',
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })
    /*====================== SWEETALERT END ======================*/
    /*======== POST LEAD CHANNELS AREA START ========*/
    const postLeadChannel = () => {
        ApiRequest.addLeadChannels({ SystemCompanyId: localStorage.getItem("SystemCompanyId") } , {
            "channelName": channelName,
            "systemCompanyId": localStorage.getItem("SystemCompanyId")
        })
            .then(response => {
                Toast.fire({
                    icon: 'success',
                    title: 'Başarıyla eklendi!'
                })
                dispatch(setLeadChannelsTrigger({ leadChannelsTrigger: !leadChannelsTrigger }))
            })
            .catch(error => { console.log(error) })
            .finally(navigate(-1))
    }
    /*======== POST LEAD CHANNELS AREA END ========*/

    const comeBack = () => {
        navigate(-1)
    }
    
  return (
    <div>
            <div className="page-header">
                <div>
                    <h1 className="page-title">Kanal Ekle</h1>
                    <Breadcrumb className="breadcrumb">
                        <Breadcrumb.Item className="breadcrumb-item" href="#">
                            Anasayfa
                        </Breadcrumb.Item>
                        <Breadcrumb.Item
                            className="breadcrumb-item active breadcrumds"
                            aria-current="page"
                        >
                            Kanal Ekle
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </div>
            </div>

            <div>
                <Card>
                    <Card.Header>
                        <Card.Title as="h3">Kanal Ekle</Card.Title>
                    </Card.Header>
                    <Card.Body>
                        <Row>
                            <Col lg={6} md={12}>
                                <FormGroup>
                                    <label htmlFor="exampleInputname">Kanal Adı</label>
                                    <Form.Control
                                        type="text"
                                        className="form-control"
                                        id="exampleInputname"
                                        placeholder='Kanal adı giriniz..'
                                        onChange={(e) => dispatch(setChannelName({ channelName: e.target.value }))}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                    </Card.Body>
                    <Card.Footer className="text-end">
                        <a to="#" onClick={postLeadChannel} className="btn btn-primary text-white mt-1 me-2">
                            Kaydet
                        </a>
                        <a href='#' onClick={comeBack} className="btn btn-danger mt-1 me-2">
                            Vazgeç
                        </a>
                    </Card.Footer>
                </Card>
            </div>
        </div>
  )
}

export default LeadChannelsAdd