import { createSlice } from "@reduxjs/toolkit";

const leadsBoardSlice = createSlice({
    name: 'leadsBoard',
    initialState: {
        leadTrigger: false,
        columns: [],
        leadList: [],
    },
    reducers: {
        setLeadTrigger: (state, action) => {
            state.leadTrigger = action.payload.leadTrigger;
        },
        setColumns: (state, action) => {
            state.columns = action.payload.columns;
        },
        setLeadList: (state, action) => {
            state.leadList = action.payload.leadList;
        },
    }
})

export const { setLeadTrigger, setColumns, setLeadList } = leadsBoardSlice.actions;

const leadsBoardReducer = leadsBoardSlice.reducer;
export default leadsBoardReducer;