import { createSlice } from "@reduxjs/toolkit";

const leadChannelsSlice = createSlice ({
    name: 'leadChannels',
    initialState: {
        channels: [],
        channelName: null,
        editChannelName: null,
        editChannelId: null,
        leadChannelsTrigger: false
    },
    reducers: {
        setChannels: (state, action) => {
            state.channels = action.payload.channels;
        },
        setChannelName: (state, action) => {
            state.channelName = action.payload.channelName;
        },
        setEditChannelName: (state, action) => {
            state.editChannelName = action.payload.editChannelName;
        },
        setEditChannelId: (state, action) => {
            state.editChannelId = action.payload.editChannelId;
        },
        setLeadChannelsTrigger: (state, action) => {
            state. leadChannelsTrigger= action.payload.leadChannelsTrigger;
        },
    }
})

export const { setChannels , setChannelName , setEditChannelName , setEditChannelId , setLeadChannelsTrigger } = leadChannelsSlice.actions;

const LeadChannelsReducer = leadChannelsSlice.reducer;
export default LeadChannelsReducer;