import { createSlice } from '@reduxjs/toolkit'

const leadStepSlice = createSlice({
    name: 'leadStep',
    initialState: {
        steps: [],
        stepName: '',
        stepStatus: '',
        editStepName: '',
        editStepStatus: '',
        leadStepId: null,
        leadTrigger: false,
    },
    reducers: {
        setSteps: (state, action) => {
            state.steps = action.payload.steps;
        },
        setStepName: (state, action) => {
            state.stepName = action.payload.stepName;
        },
        setStepStatus: (state, action) => {
            state.stepStatus = action.payload.stepStatus;
        },
        setEditStepName: (state, action) => {
            state.editStepName = action.payload.editStepName;
        },
        setEditStepStatus: (state, action) => {
            state.editStepStatus = action.payload.editStepStatus;
        },
        setLeadStepId: (state, action) => {
            state.leadStepId = action.payload.leadStepId;
        },
        setLeadTrigger: (state, action) => {
            state.leadTrigger = action.payload.leadTrigger;
        },
    }
})

export const { setSteps, setStepName, setStepStatus, setEditStepName, setEditStepStatus, setLeadStepId, setLeadTrigger } = leadStepSlice.actions;

const LeadStepReducer = leadStepSlice.reducer;
export default LeadStepReducer;
