import React, { useEffect } from 'react'
import { ApiRequest } from '../../api';
import { Breadcrumb, Card, Col, Row, Table, Toast } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { setChannels, setEditChannelId, setEditChannelName, setLeadChannelsTrigger } from '../../redux/features/leadChannels.slice';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';

function LeadChannels() {

    const MySwal = withReactContent(Swal)
    const dispatch= useDispatch();
    const { channels , editChannelName , editChannelId , leadChannelsTrigger } = useSelector(state => state.leadChannels);

    /*====================== SWEETALERT START ======================*/
    const Toast = Swal.mixin({
        toast: true,
        position: 'bottom-end',
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })
    /*====================== SWEETALERT END ======================*/
    /*======== GET LEAD CHANNELS START ========*/
    useEffect(() => {
        ApiRequest.getLeadChannels({ SystemCompanyId: localStorage.getItem("SystemCompanyId") })
            .then(response => {
                console.log("q:",response)
                dispatch(setChannels({ channels: response }))
            })
    }, [leadChannelsTrigger])
    /*======== GET LEAD CHANNELS END ========*/
    /*======== DELETE LEAD CHANNELS DELETE START ========*/
    const confirmDelete = (id) => {
        MySwal.fire({
            title: <strong>Silmek istediğinize emin misiniz?</strong>,
            icon: 'question',
            showCloseButton: true,
            showCancelButton: true,
            confirmButtonText: <button className='btn btn-success custom-btn' onClick={() => { channelDelete(id) }}>Evet</button>,
            cancelButtonText: <button className='btn btn-danger custom-btn'>Hayır</button>
        })
    }
    
    const channelDelete = (id) => {
        ApiRequest.deleteLeadChannels({ SystemCompanyId: localStorage.getItem("SystemCompanyId"), leadChannelId: id })
        .then(response => {
            Toast.fire({
                icon: 'success',
                title: 'Başarıyla Silindi!'
            })
            dispatch(setLeadChannelsTrigger({ leadChannelsTrigger: !leadChannelsTrigger }))
        })
        .catch(error => { console.log(error) })
    }
    /*======== DELETE LEAD CHANNELS DELETE END ========*/

  return (
    <div>
            <div className="page-header">
                <div>
                    <h1 className="page-title">Lead Kanallar</h1>
                    <Breadcrumb className="breadcrumb">
                        <Breadcrumb.Item className="breadcrumb-item" href="#">
                            Anasayfa
                        </Breadcrumb.Item>
                        <Breadcrumb.Item
                            className="breadcrumb-item active breadcrumds"
                            aria-current="page"
                        >
                            Lead Kanallar
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </div>
                <div className="ms-auto pageheader-btn">
                    <Link to={"/LeadChannels/Add"} className="btn btn-primary btn-icon text-white me-3">
                        <span>
                            <i className="fe fe-plus"></i>&nbsp;
                        </span>
                        Ekle
                    </Link>
                </div>
            </div>

            <Row className=" row-sm">
                <Col lg={12}>
                    <Card>
                        <Card.Header>
                            <h3 className="card-title">Lead Kanal Listesi</h3>
                        </Card.Header>
                        <Card.Body>
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Kanal Adı</th>
                                        <th>Düzenle</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {channels.map((item, index) => (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td className="text-capitalize">{item.channelName}</td>
                                            <td>
                                                <Link
                                                    to={`/leadChannels/Upsert`}
                                                    className="btn btn-primary me-2"
                                                    onClick={() => {
                                                        dispatch(setEditChannelName({ editChannelName: item.channelName }));
                                                        dispatch(setEditChannelId({ editChannelId: item.channelId }));
                                                    }}
                                                >
                                                    <i className="fa fa-edit me-1"></i>
                                                    <span>Düzenle</span>
                                                </Link>
                                                <a href="#" onClick={() => { confirmDelete(item.channelId) }} className="btn btn-danger">
                                                    <i className="fa fa-trash me-1"></i>
                                                    Sil
                                                </a>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

        </div>
  )
}

export default LeadChannels