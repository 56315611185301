import { createSlice } from '@reduxjs/toolkit'

const notesSlice = createSlice({
    name: 'noteAndTask',
    initialState: {
        /* Post Note state */
        noteText: '',
        notes: [],
        /* Post Task state */
        taskText: '',
        tasks: [],
    },
    reducers: {
        /* Post Note Reducer */
        setNoteText: (state, action) => {
            state.noteText = action.payload.noteText;
        },
        /* Get Note Reducer */
        setNotes: (state, action) => {
            state.notes = action.payload.notes;
        },
        /* Post Task Reducer */
        setTaskText: (state, action) => {
            state.taskText = action.payload.taskText;
        },
        /* Get Task Reducer */
        setTasks: (state, action) => {
            state.tasks = action.payload.tasks;
        },
    }
})

export const { setNoteText, setNotes, setTaskText, setTasks } = notesSlice.actions

const notesReducer = notesSlice.reducer;
export default notesReducer;
