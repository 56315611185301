import { configureStore } from '@reduxjs/toolkit'
import companyReducer from './features/company.slice'
import contactsReducer from './features/contacts.slice'
import IntegrationsReducer from './features/integrations.slice'
import LeadsReducer from './features/leads.slice'
import leadsBoardReducer from './features/leadsBoard.slice'
import LeadStepReducer from './features/leadStep.slice'
import listReducer from './features/list.slice'
import LoginReducer from './features/login.slice'
import notesReducer from './features/noteAndTask.slice'
import profileReducer from './features/profile.slice'
import RegisterReducer from './features/register.slice'
import userReducer from './features/users.slice'
import LeadChannelsReducer from './features/leadChannels.slice'

export const store = configureStore({
    reducer: {
        profile: profileReducer,
        user: userReducer,
        login: LoginReducer,
        register: RegisterReducer,
        leadsBoard: leadsBoardReducer,
        leads: LeadsReducer,
        leadStep: LeadStepReducer,
        leadChannels: LeadChannelsReducer,
        noteAndTask: notesReducer,
        integrations: IntegrationsReducer,
        contacts: contactsReducer,
        company: companyReducer,
        lists: listReducer
    }
})