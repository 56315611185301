import React, { useEffect } from 'react'
import { Breadcrumb, Card, Col, Form, FormGroup, Row, Modal, Button } from 'react-bootstrap'
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useLocation, useNavigate } from 'react-router-dom'
import { setContactData, setGetCompanyData, setGetContactData, setLeadButton, setLeadChannelId, setLeadChannels, setLeadId, setPostContactCompanyAdress, setPostContactCompanyData, setPostContactCompanyEmail, setPostContactCompanyName, setPostContactCompanyPhone, setPostContactCompanyWeb, setPostEmail, setPostNameSurname, setPostPhoneNumber, setSearchCompany, setSearchContact, setSelectedId, setShowAdd, setShowAddCompany, setShowCompanyModal, setShowContactModal, setSuggestions, setSuggestionsCompany, setTempData, setTempDataCompany, setTextCompany, setTextContact } from '../../redux/features/leads.slice';
import NoteAndTask from './NoteAndTask';
import { ApiRequest } from '../../api';

function LeadsEdit() {

    const MySwal = withReactContent(Swal)
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { leadTrigger, leadId, leadChannels, leadChannelId, textCompany, searchCompany, tempDataCompany, tempData, searchContact, textContact, showAdd, suggestions, getContactData, selectedId, showAddCompany, suggestionsCompany, getCompanyData, contactData, postNameSurname, postEmail, postPhoneNumber, postContactCompanyData, postContactCompanyName, postContactCompanyEmail, postContactCompanyPhone, postContactCompanyWeb, postContactCompanyAdress, leadButton, showContactModal, showCompanyModal } = useSelector(state => state.leads);

    useEffect(() => {
        dispatch(setTextContact({ textContact: '' }));
        const addedTempData = {
            "nameSurname": postNameSurname,
            "email": postEmail,
            "phoneNumber": postPhoneNumber,
            "contactCompanyName": postContactCompanyName,
        }
        dispatch(setTempData({ tempData: addedTempData }));
    }, [])

    /*====================== SWEETALERT START ======================*/
    const Toast = Swal.mixin({
        toast: true,
        position: 'bottom-end',
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })
    /*====================== SWEETALERT END ======================*/

    /*====================== CONTACT İNPUT AUTOCOMPLATE START ======================*/
    useEffect(() => {
        if (textContact == "") {
        } else {
            const loadContacts = async () => {
                const response = await ApiRequest.searchContact({ SystemCompanyId: localStorage.getItem("SystemCompanyId"), textContact: textContact })
                dispatch(setSearchContact({ searchContact: response }))
            }
            loadContacts();
        }
    }, [textContact])

    const onSuggestHandler = (selectedContact) => {
        dispatch(setTextContact({ textContact: selectedContact.nameSurname }))
        dispatch(setTempData({ tempData: selectedContact }));
        dispatch(setSelectedId({ selectedId: selectedContact.id }))
        dispatch(setSuggestions({ suggestions: [] }))
        /* SHOW RİGHT CONTENT (NOTES-TASK) */
        // dispatch(setShowRightContent({ showRightContent: true }));  //SHOW RİGHT CONTENT (NOTES-TASK)
        dispatch(setShowAdd({ showAdd: false }));
        ApiRequest.getContactData({ SystemCompanyId: localStorage.getItem("SystemCompanyId"), textContact: textContact })
            .then(response => { dispatch(setGetContactData({ getContactData: response })) })
    }

    const onChangeHandler = (newText) => {
        dispatch(setShowAdd({ showAdd: true }));
        dispatch(setTextContact({ textContact: newText }));
        dispatch(setPostNameSurname({ postNameSurname: newText }));
        let matches = []
        if (newText.length > 0) {
            matches = searchContact.filter(cnt => {
                const regex = new RegExp(`${newText}`, "gi");
                return cnt.nameSurname.match(regex)
            })
        }
        console.log('matches', matches)
        dispatch(setSuggestions({ suggestions: matches }));
        const selected = matches.filter(elm => elm.nameSurname === textContact)
        dispatch(setTempData({ tempData: selected }));
    }

    // console.log('contact temp data:', tempData);
    // console.log("text contact:", textContact);
    /*====================== CONTACT İNPUT AUTOCOMPLATE END ======================*/

    /*====================== COMPANY İNPUT AUTOCOMPLATE START ======================*/
    useEffect(() => {
        if (textCompany == "") {

        } else {
            const loadCompany = async () => {
                const response = await ApiRequest.searchCompany({ SystemCompanyId: localStorage.getItem("SystemCompanyId"), textCompany: textCompany })
                dispatch(setSearchCompany({ searchCompany: response }));
            }
            loadCompany();
        }
    }, [textCompany])

    const onSuggestHandlerCompany = (selectedCompany) => {
        dispatch(setTextCompany({ textCompany: selectedCompany.contactCompanyName }))
        dispatch(setTempDataCompany({ tempDataCompany: selectedCompany }));
        dispatch(setSuggestionsCompany({ suggestionsCompany: [] }))
        dispatch(setShowAddCompany({ showAddCompany: false }));
        ApiRequest.getCompanyData({ SystemCompanyId: localStorage.getItem("SystemCompanyId"), textCompany: textCompany })
            .then(response => { dispatch(setGetCompanyData({ getCompanyData: response })) })
    }

    const onChangeHandlerCompany = (newText) => {
        dispatch(setShowAddCompany({ showAddCompany: true }));
        dispatch(setTextCompany({ textCompany: newText }));
        dispatch(setPostContactCompanyName({ postContactCompanyName: newText }))
        let matches = []
        // console.log("searchCompany:", searchCompany)
        if (newText.length > 0) {
            matches = searchCompany.filter(cnt => {
                const regex = new RegExp(`${newText}`, "gi");
                return cnt.contactCompanyName.match(regex)
            })
        }
        // console.log('matches', matches)
        dispatch(setSuggestionsCompany({ suggestionsCompany: matches }));
        const selected = matches.filter(elm => elm.contactCompanyName === textCompany)
        dispatch(setTempDataCompany({ tempDataCompany: selected }));

    }
    // console.log("XXX :", tempDataCompany)

    /*====================== COMPANY İNPUT AUTOCOMPLATE END ======================*/
    /*====================== POST CONTACT START ======================*/
    const postContact = () => {
        const addedTempData = {
            "nameSurname": postNameSurname,
            "email": postEmail,
            "phoneNumber": postPhoneNumber,
            "contactCompanyName": postContactCompanyName,
        }
        dispatch(setTempData({ tempData: addedTempData }));
        ApiRequest.postContact({ SystemCompanyId: localStorage.getItem("SystemCompanyId") }, {
            nameSurname: postNameSurname,
            email: postEmail,
            phoneNumber: postPhoneNumber,
            contactCompanyId: tempDataCompany.contactCompanyId
        })
            .then(response => {
                dispatch(setShowContactModal({ showContactModal: false }))
                Toast.fire({
                    icon: 'success',
                    title: 'Başarıyla eklendi!'
                })
                // console.log("response :", response.data)
                dispatch(setSelectedId({ selectedId: response.id }))
            })
            .catch(error => {
                alert('Hatalı veya eskik bilgi girdiniz!');
                console.log(error)
            })
    }
    // console.log('yeni id: ', selectedId)

    /* POST İŞLEMİ SONRASI İNPUTLARI TEMİZLEMEK */
    const handleSubmit = async (e) => {
        e.preventDefault();
        dispatch(setPostNameSurname({ postNameSurname: '' }));
        dispatch(setPostPhoneNumber({ postPhoneNumber: '' }));
        dispatch(setPostEmail({ postEmail: '' }));
    }
    /*====================== POST CONTACT END ======================*/

    /*====================== POST COMPANY START ======================*/
    const postCompany = () => {
        ApiRequest.postCompany({ SystemCompanyId: localStorage.getItem("SystemCompanyId") }, {
            "contactCompanyName": postContactCompanyName,
            "workEmail": postContactCompanyEmail,
            "workPhone": postContactCompanyPhone,
            "web": postContactCompanyWeb,
            "address": postContactCompanyAdress
        })
            .then(response => {
                // console.log("ress:", response)
                dispatch(setShowCompanyModal({ showCompanyModal: false }));
                dispatch(setTextCompany({ textCompany: postContactCompanyData.contactCompanyName }));
                dispatch(setTempDataCompany({ tempDataCompany: response }));
                onChangeHandlerCompany(postContactCompanyData.contactCompanyName);
                Toast.fire({
                    icon: 'success',
                    title: 'Başarıyla eklendi!'
                })
            })
            .catch(error => {
                console.log(error)
            })
    }

    /* POST İŞLEMİ SONRASI İNPUTLARI TEMİZLEMEK */
    const handleSubmitCompany = async (e) => {
        e.preventDefault();
        dispatch(setPostContactCompanyName({ postContactCompanyName: '' }));
        dispatch(setPostContactCompanyEmail({ postContactCompanyEmail: '' }));
        dispatch(setPostContactCompanyPhone({ postContactCompanyPhone: '' }));
        dispatch(setPostContactCompanyWeb({ postContactCompanyWeb: '' }));
        dispatch(setPostContactCompanyAdress({ postContactCompanyAdress: '' }));
    }
    /*====================== POST COMPANY END ======================*/
    /*====================== GET LEAD CHANNELS STARD ======================*/
    useEffect(() => {
        ApiRequest.getLeadChannels({ SystemCompanyId: localStorage.getItem("SystemCompanyId") })
            .then(response => {
                dispatch(setLeadChannels({ leadChannels: response }))
            })
    }, [])
    /*====================== GET LEAD CHANNELS END ======================*/
    /*====================== PUT LEAD START ======================*/
    const putLead = (e) => {
        e.preventDefault();
        ApiRequest.putLead({ SystemCompanyId: localStorage.getItem("SystemCompanyId"), leadId: leadId }, {
            "systemCompanyId": JSON.parse(`${localStorage.getItem("SystemCompanyId")}`),
            "responsibleUserId": JSON.parse(`${localStorage.getItem("ResponsibleUserId")}`),
            "contactId": selectedId,
            "leadChannelId": JSON.parse(leadChannelId)
        })
            .then(response => {
                Toast.fire({
                    icon: 'success',
                    title: 'Başarıyla kaydedildi!'
                })
                dispatch(setTextContact({ textContact: '' }));
                navigate(-1)
            })
            .catch(error => {
                console.log(error)
            })
    }
    // console.log("LeadId güncel :", leadId)
    /*====================== PUT LEAD END ======================*/

    /* FOR MODAL CONTACT MODAL */
    const handleCloseContact = () => dispatch(setShowContactModal({ showContactModal: false }));
    const handleShowContact = (e) => {
        e.preventDefault();
        dispatch(setShowContactModal({ showContactModal: true }));
        dispatch(setShowAdd({ showAdd: false }));
    }

    /* FOR MODAL COMPANY MODAL */
    const handleCloseCompany = () => dispatch(setShowCompanyModal({ showCompanyModal: false }));
    const handleShowCompany = (e) => {
        e.preventDefault();
        dispatch(setShowCompanyModal({ showCompanyModal: true }))
        dispatch(setShowAddCompany({ showAddCompany: false }));
    }

    const comeBack = () => {
        navigate(-1)
    }

    return (
        <div>
            <div className="page-header">
                <div>
                    <h1 className="page-title">Lead Ekle</h1>
                    <Breadcrumb className="breadcrumb">
                        <Breadcrumb.Item className="breadcrumb-item" href="#">
                            Leads
                        </Breadcrumb.Item>
                        <Breadcrumb.Item
                            className="breadcrumb-item active breadcrumds"
                            aria-current="page"
                        >
                            Lead Ekle
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </div>
            </div>

            <Row className="row-sm">
                <Col sm={12} md={12} lg={5} xl={4}>
                    <Card className=" custom-card">
                        <div className="main-content-app pt-0">
                            <div className="main-content-left main-content-left-chat">
                                <Card.Body>
                                    <div className="input">
                                        <div className='lead-id-content mb-3'>
                                            <span className='fs-5 fw-semibold'>Lead ID: #{leadId} </span>
                                        </div>
                                        <div>
                                            <button onClick={comeBack} className='btn btn-primary'><i className="fa fa-arrow-left"></i> Geri</button>
                                        </div>
                                        <Form>
                                            <div className='form-title-area my-3'>
                                                <span className='fs-5 fw-semibold'>Contact</span>
                                                <div className='line'></div>
                                            </div>
                                            <input
                                                type="text"
                                                className='form-control'
                                                onChange={e => onChangeHandler(e.target.value)}
                                                value={textContact}
                                                onBlur={() => {
                                                    setTimeout(() => {
                                                        dispatch(setSuggestions({ suggestions: [] }))
                                                    }, 100);
                                                }}
                                            />
                                            <div>
                                                {textContact &&
                                                    <>
                                                        {suggestions.map((suggestion, i) => <div key={i} className="suggestion suggestion-list" onClick={() => onSuggestHandler(suggestion)}>
                                                            {suggestion.nameSurname}
                                                        </div>)}
                                                        {showAdd && <div className={suggestions.leadId != null ? "suggestion suggestion-add" : "suggestion suggestion-add"}>
                                                            <button className="d-flex justify-content-between align-items-center w-100" onClick={handleShowContact}>Contact Ekle <i className="fe fe-plus"></i>
                                                            </button>
                                                        </div>}
                                                    </>
                                                }
                                            </div>
                                            <div className='form-title-area my-3'>
                                                <span className='fs-5 fw-semibold'>Lead Channel</span>
                                                <div className='line'></div>
                                            </div>
                                            <select className='form-select' name="leadChannels" id="leadChannels" defaultValue='default' onChange={(e) => dispatch(setLeadChannelId({ leadChannelId: e.target.value }))}>
                                                <option value='default' disabled>Seçiniz..</option>
                                                {leadChannels.map((item, index) => (
                                                    <option value={item.channelId} key={index}>{item.channelName}</option>
                                                ))}
                                            </select>
                                            <div className='form-title-area my-3'>
                                                <span className='fs-5 fw-semibold'>Contact Information</span>
                                                <div className='line'></div>
                                            </div>
                                            <div className='py-3'>
                                                <span className='fw-semibold'>Ad Soyad : </span>
                                                <span>{tempData?.nameSurname}</span>
                                            </div>
                                            <div className='py-3'>
                                                <span className='fw-semibold'>Telefon Numarası : </span>
                                                <span>{tempData?.phoneNumber}</span>
                                            </div>
                                            <div className='py-3'>
                                                <span className='fw-semibold'>Email : </span>
                                                <span>{tempData?.email}</span>
                                            </div>
                                            <div className='py-3'>
                                                <span className='fw-semibold'>Şirket Adı : </span>
                                                <span>{tempData?.contactCompanyName}</span>
                                            </div>
                                            <div className='text-end'>
                                                <button className='btn btn-primary' onClick={putLead}>Kaydet</button>
                                            </div>
                                        </Form>
                                        <div>
                                            {/* CONTACT MODAL */}
                                            <Modal show={showContactModal} onHide={handleCloseContact}>
                                                <Form onSubmit={handleSubmit}>
                                                    <Modal.Header closeButton>
                                                        <Modal.Title>Contact Ekle</Modal.Title>
                                                    </Modal.Header>
                                                    <Modal.Body>
                                                        <Row>
                                                            <Col lg={6} md={12} className="mb-2">
                                                                <FormGroup>
                                                                    <label htmlFor="nameSurname">Ad Soyad</label>
                                                                    <Form.Control
                                                                        type="text"
                                                                        className="form-control"
                                                                        id="nameSurname"
                                                                        placeholder="Ad soyad"
                                                                        onChange={(e) => dispatch(setPostNameSurname({ postNameSurname: e.target.value }))}
                                                                        value={postNameSurname}
                                                                        required
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                            <Col lg={6} md={12} className="mb-2">
                                                                <FormGroup>
                                                                    <label htmlFor="phoneNumber">Telefon Numarası</label>
                                                                    <Form.Control
                                                                        type="number"
                                                                        className="form-control"
                                                                        id="phoneNumber"
                                                                        placeholder="Telefon Numarası"
                                                                        onChange={(e) => dispatch(setPostPhoneNumber({ postPhoneNumber: e.target.value }))}
                                                                        value={postPhoneNumber}
                                                                        required
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                            <Col lg={6} md={12} className="mb-2">
                                                                <FormGroup>
                                                                    <label htmlFor="email">Email</label>
                                                                    <Form.Control
                                                                        type="text"
                                                                        className="form-control"
                                                                        id="email"
                                                                        placeholder="Email"
                                                                        onChange={(e) => dispatch(setPostEmail({ postEmail: e.target.value }))}
                                                                        value={postEmail}
                                                                        required
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                            <Col lg={6} md={12} className="mb-2">
                                                                <FormGroup>
                                                                    <label htmlFor="companyName">Şirket Adı</label>
                                                                    <input
                                                                        type="text"
                                                                        className='form-control'
                                                                        onChange={e => onChangeHandlerCompany(e.target.value)}
                                                                        value={textCompany}
                                                                        onBlur={() => {
                                                                            setTimeout(() => {
                                                                                dispatch(setSuggestionsCompany({ suggestionsCompany: [] }))
                                                                                setSuggestionsCompany([])
                                                                            }, 100);
                                                                        }}
                                                                    />
                                                                    <div>
                                                                        {textCompany &&
                                                                            <>
                                                                                {suggestionsCompany.map((suggestion, i) => <div key={i} className="suggestion suggestion-list" onClick={() => onSuggestHandlerCompany(suggestion)}>
                                                                                    {suggestion.contactCompanyName}
                                                                                </div>)}
                                                                                {showAddCompany && <div className={suggestions.leadId != null ? "suggestion suggestion-add" : "suggestion suggestion-add"}>
                                                                                    <button className="d-flex justify-content-between align-items-center w-100" onClick={handleShowCompany}>Şirket Ekle <i className="fe fe-plus"></i>
                                                                                    </button>
                                                                                </div>}
                                                                            </>
                                                                        }
                                                                        {suggestions.map((suggestion, i) => <div key={i} className="suggestion suggestion-list" onClick={() => onSuggestHandler(suggestion)}>
                                                                            {suggestion.nameSurname}
                                                                        </div>)}
                                                                        {showAdd && <div className={suggestions.leadId != null ? "suggestion suggestion-add" : "suggestion suggestion-add"}>
                                                                            <button className="d-flex justify-content-between align-items-center w-100" onClick={handleShowContact}>Contact Ekle <i className="fe fe-plus"></i>
                                                                            </button>
                                                                        </div>}
                                                                    </div>
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                    </Modal.Body>
                                                    <Modal.Footer>
                                                        <Button variant="secondary" onClick={handleCloseContact}>
                                                            Close
                                                        </Button>
                                                        <Button variant="primary" type='submit' onClick={postContact} >
                                                            Save Changes
                                                        </Button>
                                                    </Modal.Footer>
                                                </Form>
                                            </Modal>
                                            {/* COMPANY MODAL */}
                                            <Modal show={showCompanyModal} onHide={handleCloseCompany} animation={false}>
                                                <Form onSubmit={handleSubmitCompany}>
                                                    <Modal.Header closeButton>
                                                        <Modal.Title>Şirket Ekle</Modal.Title>
                                                    </Modal.Header>
                                                    <Modal.Body>
                                                        <Row>
                                                            <Col lg={12} md={12} className="mb-2">
                                                                <FormGroup>
                                                                    <label htmlFor="contactCompanyName">Şirket Adı</label>
                                                                    <Form.Control
                                                                        type="text"
                                                                        className="form-control"
                                                                        id="contactCompanyName"
                                                                        placeholder="Şirket Adı"
                                                                        onChange={(e) => dispatch(setPostContactCompanyName({ postContactCompanyName: e.target.value }))}
                                                                        value={postContactCompanyName}
                                                                        required
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                            <Col lg={6} md={12} className="mb-2">
                                                                <FormGroup>
                                                                    <label htmlFor="contactCompanyEmail">Şirket Email</label>
                                                                    <Form.Control
                                                                        type="email"
                                                                        className="form-control"
                                                                        id="contactCompanyEmail"
                                                                        placeholder="Şirket Email"
                                                                        onChange={(e) => dispatch(setPostContactCompanyEmail({ postContactCompanyEmail: e.target.value }))}
                                                                        value={postContactCompanyEmail}
                                                                        required
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                            <Col lg={6} md={12} className="mb-2">
                                                                <FormGroup>
                                                                    <label htmlFor="contactCompanyPhone">Telefon Numarası</label>
                                                                    <Form.Control
                                                                        type="number"
                                                                        className="form-control"
                                                                        id="contactCompanyPhone"
                                                                        placeholder="Telefon Numarası"
                                                                        onChange={(e) => dispatch(setPostContactCompanyPhone({ postContactCompanyPhone: e.target.value }))}
                                                                        value={postContactCompanyPhone}
                                                                        required
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                            <Col lg={6} md={12} className="mb-2">
                                                                <FormGroup>
                                                                    <label htmlFor="contactCompanyWeb">Website</label>
                                                                    <Form.Control
                                                                        type="text"
                                                                        className="form-control"
                                                                        id="contactCompanyWeb"
                                                                        placeholder="Website"
                                                                        onChange={(e) => dispatch(setPostContactCompanyWeb({ postContactCompanyWeb: e.target.value }))}
                                                                        value={postContactCompanyWeb}
                                                                        required
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                            <Col lg={6} md={12} className="mb-2">
                                                                <FormGroup>
                                                                    <label htmlFor="contactCompanyAdress">Adress</label>
                                                                    <Form.Control
                                                                        type="text"
                                                                        className="form-control"
                                                                        id="contactCompanyAdress"
                                                                        placeholder="Adress"
                                                                        onChange={(e) => dispatch(setPostContactCompanyAdress({ postContactCompanyAdress: e.target.value }))}
                                                                        value={postContactCompanyAdress}
                                                                        required
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                    </Modal.Body>
                                                    <Modal.Footer>
                                                        <Button variant="secondary" onClick={handleCloseCompany}>
                                                            Close
                                                        </Button>
                                                        <Button variant="primary" type='submit' onClick={postCompany}>
                                                            Save Changes
                                                        </Button>
                                                    </Modal.Footer>
                                                </Form>
                                            </Modal>
                                        </div>
                                    </div>
                                </Card.Body>
                            </div>
                        </div>
                    </Card>
                </Col>
                <Col sm={12} md={12} lg={7} xl={8}>
                    <Card className="custom-card">
                        <div className="main-content-app pt-0">
                            <NoteAndTask />
                        </div>
                    </Card>
                </Col>
            </Row >

        </div >
    )
}

export default LeadsEdit