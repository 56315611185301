import { createSlice } from "@reduxjs/toolkit"

const userSlice = createSlice({
    name: 'user',
    initialState: {
        userTrigger: false,
        userLists: [],
        userName: '',
        userSurname: '',
        userEmail: '',
        userPwd: '',
        userPhone: '',
        editUserName: '',
        editUserSurname: '',
        editUserEmail: '',
        editUserPhone: '',
        UserId: null,
        editUserPwd: '',
        editUserPwdConfirm: '',
    },
    reducers: {
        setUserTrigger : (state, action) => {
            state.userTrigger = action.payload.userTrigger
        },
        setUserLists : (state, action) => {
            state.userLists = action.payload.userLists
        },
        setUserName : (state, action) => {
            state.userName = action.payload.userName
        },
        setUserSurname : (state, action) => {
            state.userSurname = action.payload.userSurname
        },
        setUserEmail : (state, action) => {
            state.userEmail = action.payload.userEmail
        },
        setUserPwd : (state, action) => {
            state.userPwd = action.payload.userPwd
        },
        setUserPhone : (state, action) => {
            state.userPhone = action.payload.userPhone
        },
        setEditUserName : (state, action) => {
            state.editUserName = action.payload.editUserName
        },
        setEditUserSurname : (state, action) => {
            state.editUserSurname = action.payload.editUserSurname
        },
        setEditUserEmail : (state, action) => {
            state.editUserEmail = action.payload.editUserEmail
        },
        setEditUserPhone : (state, action) => {
            state.editUserPhone = action.payload.editUserPhone
        },
        setUserId : (state, action) => {
            state.UserId = action.payload.UserId
        },
        setEditUserPwd : (state, action) => {
            state.editUserPwd = action.payload.editUserPwd
        },
        setEditUserPwdConfirm : (state, action) => {
            state.editUserPwdConfirm = action.payload.editUserPwdConfirm
        },
    }
});

export const { setUserTrigger, setUserLists, setUserName, setUserSurname, setUserEmail, setUserPwd, setUserPhone, setEditUserName, setEditUserSurname, setEditUserEmail, setEditUserPhone, setUserId, setEditUserPwd, setEditUserPwdConfirm } = userSlice.actions;

const userReducer = userSlice.reducer;
export default userReducer;