import { createSlice } from '@reduxjs/toolkit'

const leadsSlice = createSlice({
    name: 'leads',
    initialState: {
        /* Lead Kanban Page */
        leadTrigger: false,
        /* Post lead states */
        leadId: null,
        leadChannels: [],
        leadChannelId: null,
        /* Contact autocomplate states */
        searchContact: '',
        textContact: '',
        tempData: null,
        showAdd: true,
        suggestions: [],
        getContactData: null,
        selectedId: null,
        /* Copmany autocomplate states */
        searchCompany: '',
        textCompany: '',
        tempDataCompany: '',
        showAddCompany: '',
        suggestionsCompany: [],
        getCompanyData: null,
        /* Post contact states */
        contactData: '',
        postNameSurname: '',
        postEmail: '',
        postPhoneNumber: '',
        /* Post company states */
        postContactCompanyData: '',
        postContactCompanyName: '',
        postContactCompanyEmail: '',
        postContactCompanyPhone: '',
        postContactCompanyWeb: '',
        postContactCompanyAdress: '',
        /* Show lead button states */
        leadButton: true,
        /* Show contact modal states */
        showContactModal: false,
        /* Show company modal states */
        showCompanyModal: false,
        /* Show Note and task component states */
        showRightContent: false,
    },
    reducers: {
        setLeadTrigger: (state, action) => {
            state.leadTrigger = action.payload.leadTrigger;
        },
        /* Post lead reducers */
        setLeadId: (state, action) => {
            state.leadId = action.payload.leadId;
        },
        setLeadChannels: (state, action) => {
            state.leadChannels = action.payload.leadChannels;
        },
        setLeadChannelId: (state, action) => {
            state.leadChannelId = action.payload.leadChannelId;
        },
        /* Contact autocomplate reducers */
        setSearchContact: (state, action) => {
            state.searchContact = action.payload.searchContact;
        },
        setTextContact: (state, action) => {
            state.textContact = action.payload.textContact;
        },
        setTempData: (state, action) => {
            state.tempData = action.payload.tempData;
        },
        setShowAdd: (state, action) => {
            state.showAdd = action.payload.showAdd;
        },
        setSuggestions : (state, action) => {
            state.suggestions = action.payload.suggestions;
        },
        setGetContactData : (state, action) => {
            state.getContactData = action.payload.getContactData;
        },
        setSelectedId : (state, action) => {
            state.selectedId = action.payload.selectedId;
        },
        /* Copmany autocomplate reducers */
        setSearchCompany: (state, action) => {
            state.searchCompany = action.payload.searchCompany;
        },
        setTextCompany: (state, action) => {
            state.textCompany = action.payload.textCompany;
        },
        setTempDataCompany: (state, action) => {
            state.tempDataCompany = action.payload.tempDataCompany;
        },
        setShowAddCompany: (state, action) => {
            state.showAddCompany = action.payload.showAddCompany;
        },
        setSuggestionsCompany: (state, action) => {
            state.suggestionsCompany = action.payload.suggestionsCompany;
        },
        setGetCompanyData: (state, action) => {
            state.getCompanyData = action.payload.getCompanyData;
        },
        /* Post leads reducers */
        setContactData: (state, action) => {
            state.contactData = action.payload.contactData;
        },
        setPostNameSurname: (state, action) => {
            state.postNameSurname = action.payload.postNameSurname;
        },
        setPostEmail: (state, action) => {
            state.postEmail = action.payload.postEmail;
        },
        setPostPhoneNumber: (state, action) => {
            state.postPhoneNumber = action.payload.postPhoneNumber;
        },
        /* Post company reducers */
        setPostContactCompanyData: (state, action) => {
            state.postContactCompanyData = action.payload.postContactCompanyData;
        },
        setPostContactCompanyName: (state, action) => {
            state.postContactCompanyName = action.payload.postContactCompanyName;
        },
        setPostContactCompanyEmail: (state, action) => {
            state.postContactCompanyEmail = action.payload.postContactCompanyEmail;
        },
        setPostContactCompanyPhone: (state, action) => {
            state.postContactCompanyPhone = action.payload.postContactCompanyPhone;
        },
        setPostContactCompanyWeb: (state, action) => {
            state.postContactCompanyWeb = action.payload.postContactCompanyWeb;
        },
        setPostContactCompanyAdress: (state, action) => {
            state.postContactCompanyAdress = action.payload.postContactCompanyAdress;
        },
        /* Show lead button reducers */
        setLeadButton: (state, action) => {
            state.leadButton = action.payload.leadButton;
        },
        /* Show contact modal reducers */
        setShowContactModal: (state, action) => {
            state.showContactModal = action.payload.showContactModal;
        },
        /* Show company modal reducers */
        setShowCompanyModal: (state, action) => {
            state.showCompanyModal = action.payload.showCompanyModal;
        },
        /* Show company modal reducers */
        setShowRightContent: (state, action) => {
            state.showRightContent = action.payload.showRightContent;
        },
    }
})

export const { setLeadTrigger, setLeadId , setLeadChannels , setLeadChannelId , leadChannels ,setTempData, setSearchContact, setTextContact, setShowAdd, setSuggestions, setGetContactData, setSelectedId,setSearchCompany, setTextCompany, setTempDataCompany, setShowAddCompany,setSuggestionsCompany, setGetCompanyData, setContactData, setPostNameSurname, setPostEmail, setPostPhoneNumber, setPostContactCompanyData, setPostContactCompanyName, setPostContactCompanyEmail, setPostContactCompanyPhone, setPostContactCompanyWeb, setPostContactCompanyAdress, setLeadButton, setShowContactModal, setShowCompanyModal, setShowRightContent } = leadsSlice.actions;

const LeadsReducer = leadsSlice.reducer;
export default LeadsReducer;
