import React from 'react'
import { Breadcrumb, Button, Card, Col, Form, FormGroup, Row } from 'react-bootstrap'
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2';
import { setEditUserEmail, setEditUserName, setEditUserPhone, setEditUserSurname, setUserId, setUserPwd, setEditUserPwd, setEditUserPwdConfirm } from '../../redux/features/users.slice';
import { ApiRequest } from '../../api';

function UsersEdit() {
    // Link ten parametre olarak gönderilen veriyi "useLocation" ile alabiliyoruz çünkü ikisi de 'react-router-dom' yapısı kullandığı için alabilmekteyiz.
    const location = useLocation();
    // console.log('params: ', location);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { editUserName, editUserSurname, editUserEmail, editUserPhone, UserId, editUserPwd, editUserPwdConfirm } = useSelector(state => state.user)

    // useEffect(() => {
    //     if (location.state === null) { } else {
    //         dispatch(setEditUserName({ editUserName: location.state.item.name }));
    //         dispatch(setEditUserSurname({ editUserSurname: location.state.item.surName }));
    //         dispatch(setEditUserEmail({ editUserEmail: location.state.item.email }));
    //         dispatch(setEditUserPhone({ editUserPhone: location.state.item.phoneNumber }));
    //         dispatch(setUserId({ UserId: location.state.item.userId }));
    //     }
    // }, [])

    /*====================== SWEETALERT START ======================*/
    const Toast = Swal.mixin({
        toast: true,
        position: 'bottom-end',
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })
    /*====================== SWEETALERT END ======================*/
    /*====================== EDIT USER INFO START ======================*/
    const editUser = () => {
        ApiRequest.editUsers({ SystemCompanyId: localStorage.getItem("SystemCompanyId"), UserId: UserId }, {
            "name": editUserName,
            "surname": editUserSurname,
            "email": editUserEmail,
            "phoneNumber": editUserPhone
        })
        .then(response => {
            Toast.fire({
                icon: 'success',
                title: 'Başarıyla Değiştirildi!'
            })
        })
        .catch(error => {
            console.log(error)
            Toast.fire({
                icon: 'error',
                title: error.response.data.Message
            })
        })
        .finally(function () {
            navigate(-1);
        })
    }
    /*====================== EDIT USER INFO END ======================*/

     /*====================== EDIT USER PWD START ======================*/
     const editUserPassword = () => {
        ApiRequest.editUsersPassword({ SystemCompanyId: localStorage.getItem("SystemCompanyId"), UserId: UserId }, {
            "newPassword": editUserPwd,
            "confirmPassword": editUserPwdConfirm
        })
            .then(response => {
                Toast.fire({
                    icon: 'success',
                    title: 'Başarıyla düzenlendi!'
                })
            })
            .catch(error => {
                console.log(error)
                Toast.fire({
                    icon: 'error',
                    title: 'Başarıyla düzenlenemedi!'
                })
            })
            .finally(function () {
                navigate(-1);
            })
    }
    /*====================== EDIT USER PWD END ======================*/

    /* POST İŞLEMİ SONRASI İNPUTLARI TEMİZLEMEK */
    const handleSubmitUser = async (e) => {
        e.preventDefault();
        dispatch(setEditUserName({ editUserName: '' }));
        dispatch(setEditUserSurname({ editUserSurname: '' }));
        dispatch(setEditUserEmail({ editUserEmail: '' }));
        dispatch(setEditUserPhone({ editUserPhone: '' }));
        dispatch(setEditUserPwd({ editUserPwd: '' }));
        dispatch(setEditUserPwdConfirm({ editUserPwdCofirm: '' }));
    }

    const comeBack = () => {
        navigate(-1)
    }

    return (
        <div>
            <div className="page-header">
                <div>
                    <h1 className="page-title">Kullanıcı Düzenle</h1>
                    <Breadcrumb className="breadcrumb">
                        <Breadcrumb.Item className="breadcrumb-item" href="#">
                            Kullanıcılar
                        </Breadcrumb.Item>
                        <Breadcrumb.Item
                            className="breadcrumb-item active breadcrumds"
                            aria-current="page"
                        >
                            Kullanıcı Düzenle
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </div>
            </div>

            <div>
                <Form onSubmit={handleSubmitUser}>
                    <Card>
                        <Card.Header>
                            <Card.Title as="h3">Kullanıcı Bilgileri Güncelleme</Card.Title>
                        </Card.Header>
                        <Card.Body>
                            <Row>
                                <Col lg={6} md={12}>
                                    <FormGroup>
                                        <label htmlFor="exampleInputname">İsim</label>
                                        <Form.Control
                                            type="text"
                                            className="form-control"
                                            id="exampleInputname"
                                            placeholder="İsim Soyisim"
                                            defaultValue={editUserName}
                                            onChange={(e) => dispatch(setEditUserName({ editUserName: e.target.value }))}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col lg={6} md={12}>
                                    <FormGroup>
                                        <label htmlFor="exampleInputsurname">Soyisim</label>
                                        <Form.Control
                                            type="text"
                                            className="form-control"
                                            id="exampleInputsurname"
                                            placeholder="İsim Soyisim"
                                            defaultValue={editUserSurname}
                                            onChange={(e) => dispatch(setEditUserSurname({ editUserSurname: e.target.value }))}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={6} md={12}>
                                    <FormGroup className="mt-2">
                                        <label htmlFor="exampleInputEmail1">Email Adresi</label>
                                        <Form.Control
                                            type="email"
                                            className="form-control"
                                            id="exampleInputEmail1"
                                            placeholder="Email Adresi"
                                            defaultValue={editUserEmail}
                                            onChange={(e) => dispatch(setEditUserEmail({ editUserEmail: e.target.value }))}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col lg={6} md={12}>
                                    <FormGroup className="mt-2">
                                        <label htmlFor="exampleInputnumber">Telefon Numarası</label>
                                        <Form.Control
                                            type="tel"
                                            onKeyPress={(event) => {
                                                if (!/[0-9]/.test(event.key)) {
                                                  event.preventDefault();
                                                }
                                              }}
                                            className="form-control"
                                            id="exampleInputnumber"
                                            placeholder="Telefon Numarası"
                                            defaultValue={editUserPhone}
                                            onChange={(e) => dispatch(setEditUserPhone({ editUserPhone: e.target.value }))}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Card.Body>
                        <Card.Footer className="text-end">
                            <Button type="submit" onClick={editUser} className="btn btn-success text-white mt-1 me-2">
                                Kaydet
                            </Button>
                            <Link to="#" onClick={comeBack} className="btn btn-danger mt-1 me-2">
                                Vazgeç
                            </Link>
                        </Card.Footer>
                    </Card>
                </Form>
            </div>
            
            <div>
                <Form onSubmit={handleSubmitUser}>
                    <Card>
                        <Card.Header>
                            <Card.Title as="h3">Kullanıcı Şifre Güncelleme</Card.Title>
                        </Card.Header>
                        <Card.Body>
                            <Row>
                                <Col lg={6} md={12}>
                                    <FormGroup className="mt-2">
                                        <label htmlFor="exampleInputPwd">Yeni Şifre</label>
                                        <Form.Control
                                            type="password"
                                            className="form-control"
                                            id="exampleInputPwd"
                                            autoComplete=''
                                            placeholder='Yeni şifre giriniz..'
                                            onChange={(e) => dispatch(setEditUserPwd({ editUserPwd: e.target.value }))}
                                            required
                                        />
                                    </FormGroup>
                                </Col>
                                <Col lg={6} md={12}>
                                    <FormGroup className="mt-2">
                                        <label htmlFor="exampleInputPwdConfirm">Yeni Şifre Tekrar</label>
                                        <Form.Control
                                            type="password"
                                            className="form-control"
                                            id="exampleInputPwdConfirm"
                                            autoComplete=''
                                            placeholder='Yeni şifre giriniz..'
                                            onChange={(e) => dispatch(setEditUserPwdConfirm({ editUserPwdConfirm: e.target.value }))}
                                            required
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Card.Body>
                        <Card.Footer className="text-end">
                            <Button type="submit" onClick={editUserPassword} className="btn btn-success text-white mt-1 me-2">
                                Kaydet
                            </Button>
                            <Link to="#" onClick={comeBack} className="btn btn-danger mt-1 me-2">
                                Vazgeç
                            </Link>
                        </Card.Footer>
                    </Card>
                </Form>
            </div>
        </div>
    )
}

export default UsersEdit