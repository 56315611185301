import React, { useEffect } from 'react'
import { useState } from 'react';
import { Scrollbars } from "react-custom-scrollbars";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { Form } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { setNotes, setNoteText, setTasks, setTaskText } from '../../redux/features/noteAndTask.slice';
import { ApiRequest } from '../../api';

function NoteAndTask() {

    const dispatch = useDispatch();
    const { noteText, notes, taskText, tasks } = useSelector(state => state.noteAndTask);
    const { leadId } = useSelector(state => state.leads);
    const [trigger, setTrigger] = useState(false);

    /*====================== SWEETALERT START ======================*/
    const Toast = Swal.mixin({
        toast: true,
        position: 'bottom-end',
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })
    /*====================== SWEETALERT END ======================*/

    /*====================== POST NOTES START ======================*/
    const postMessages = async () => {
        /* Post Note */
        if (noteText == '') { } else {
            ApiRequest.postNotes({ SystemCompanyId: localStorage.getItem("SystemCompanyId") }, {
                "noteDetail": noteText,
                "leadId": leadId
            })
                .then(response => {
                    Toast.fire({
                        icon: 'success',
                        title: 'Başarıyla Eklendi!'
                    })
                })
                .catch(error => { console.log(error) });
        }
        /* Post Task */
        if (taskText == '') { } else {
            ApiRequest.postTasks({ SystemCompanyId: localStorage.getItem("SystemCompanyId") }, {
                "taskDetail": taskText,
                "leadId": leadId
            })
                .then(response => {
                    Toast.fire({
                        icon: 'success',
                        title: 'Başarıyla Eklendi!'
                    })
                })
                .catch(error => { console.log(error) });
        }
        setTrigger(!trigger);
    }

    /* POST İŞLEMİ SONRASI İNPUTLARI TEMİZLEMEK */
    const handleSubmitNoteAndTask = async (e) => {
        e.preventDefault();
        dispatch(setNoteText({ noteText: '' }))
        dispatch(setTaskText({ taskText: '' }))
    }
    /*====================== POST LEADS END ======================*/
    /*====================== GET NOTES START ======================*/
    const getNotes = () => {
        ApiRequest.getNotes({ SystemCompanyId: localStorage.getItem("SystemCompanyId"), leadId: leadId })
        .then(response => { dispatch(setNotes({ notes: response })) })
        .catch(error => { console.log(error) })
    }
    // console.log("get notes :", notes)
    /*====================== GET NOTES END ======================*/
    /*====================== GET TASKS START ======================*/
    const getTasks = () => {
        ApiRequest.getTasks({ SystemCompanyId: localStorage.getItem("SystemCompanyId"), leadId: leadId })
        .then(response => { dispatch(setTasks({ tasks: response })) })
        .catch(error => { console.log(error) })
    }
    // console.log("get tasks :", notes)
    /*====================== GET TASKS END ======================*/

    useEffect(() => {
        getNotes();
        getTasks();
    }, [trigger])

    return (
        <div className="main-content-body main-content-body-chat">
            <div className="main-chat-body" id="ChatBody">
                <Scrollbars   >
                    <div className="content-inner">
                        <label className="main-chat-time">
                            <span>Now</span>
                        </label>
                        <div className="media flex-row-reverse chat-right">
                            <div className="main-img-user online">
                                <img
                                    alt="avatar"
                                    src={require("../../assets/images/users/2.jpg")}
                                />
                            </div>
                            <div className="media-body">
                                {notes.map((item, index) =>
                                    <div className="main-msg-wrapper" key={index}>
                                        <span>Note :</span> {item.noteDetail}
                                    </div>
                                )}
                                <div>
                                    <span>9:48 am</span>
                                    <Link to="#">
                                        <i className="icon ion-android-more-horizontal"></i>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="media flex-row-reverse chat-left">
                            <div className="media-body align-items-start">
                                {tasks.map((item, index) =>
                                    <div className="main-msg-wrapper" key={index}>
                                        <span>Task :</span> {item.taskDetail}
                                    </div>
                                )}
                                <div>
                                    <span>9:48 am</span>
                                    <Link to="#">
                                        <i className="icon ion-android-more-horizontal"></i>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </Scrollbars>
            </div>
            <div className="main-chat-footer h-auto d-block my-3">
                <Form onClick={handleSubmitNoteAndTask}>
                    <div className="panel panel-primary">
                        <div className="tab-menu-heading border-0 p-0">
                            <div className="tabs-menu1 tabstyle2">
                                <Tabs
                                    variant="pills"
                                    defaultActiveKey="notes"
                                    className="panel-tabs"
                                >
                                    <Tab eventKey="notes" className="me-1" title="Notes">
                                        <hr />
                                        <div className="mb-3">
                                            <textarea
                                                className="form-control"
                                                id="notesTextarea"
                                                placeholder="Your Notes"
                                                onChange={(e) => dispatch(setNoteText({ noteText: e.target.value }))}
                                                value={noteText}
                                            ></textarea>
                                        </div>
                                    </Tab>
                                    <Tab eventKey="task" className="me-1" title="Task">
                                        <hr />
                                        <div className="mb-3">
                                            <textarea
                                                className="form-control"
                                                id="notesTextarea"
                                                placeholder="Your Task"
                                                onChange={(e) => dispatch(setTaskText({ taskText: e.target.value }))}
                                                value={taskText}
                                            ></textarea>
                                        </div>
                                    </Tab>
                                </Tabs>
                            </div>
                        </div>
                    </div>
                    <div className='text-end'>
                        <button
                            type='submit'
                            onClick={postMessages}
                            className="btn btn-success mt-1 me-2"
                        >
                            Kaydet
                        </button>
                    </div>
                </Form>
            </div>
        </div>
    )
}

export default NoteAndTask