import axios from "axios";


const instance = axios.create({
    baseURL: 'https://bidycrmapi.bidy.com.tr',
    timeout: 60000,
    headers: {
        'Content-Type': 'application/json'
    }
});

const responseBody = (response) => response.data;

const request = {
    get: (url) => instance.get(url).then(responseBody),
    post: (url, body) => instance.post(url, body).then(responseBody),
    put: (url, body) => instance.put(url, body).then(responseBody),
    delete: (url, body) => instance.delete(url, body).then(responseBody),
};

export const ApiRequest = {
    postLogin: (payload) => request.post(`/api/Auth/login`, payload),
    postRegister: (payload) => request.post(`/api/Auth/register`, payload),
    searchContact: ({ SystemCompanyId, textContact }, payload) => request.get(`/system-companies/${SystemCompanyId}/contacts/look-up/${textContact}`, payload),
    getContactData: ({ SystemCompanyId, textContact }, payload) => request.get(`/system-companies/${SystemCompanyId}/contacts/look-up/${textContact}`, payload),
    searchCompany: ({ SystemCompanyId, textCompany }, payload) => request.get(`/system-companies/${SystemCompanyId}/contact-companies/look-up/${textCompany}`, payload),
    getCompanyData: ({ SystemCompanyId, textCompany }, payload) => request.get(`/system-companies/${SystemCompanyId}/contact-companies/look-up/${textCompany}`, payload),
    postContact: ({ SystemCompanyId }, payload) => request.post(`/system-companies/${SystemCompanyId}/contacts`, payload),
    postCompany: ({ SystemCompanyId }, payload) => request.post(`/system-companies/${SystemCompanyId}/contact-companies`, payload),
    postLead: ({ SystemCompanyId, ResponsibleUserId }, payload) => request.post(`/system-companies/${SystemCompanyId}/${ResponsibleUserId}/leads`, payload),
    putLead: ({ SystemCompanyId, leadId }, payload) => request.put(`/system-companies/${SystemCompanyId}/leads/${leadId}`, payload),
    getCols: ({ SystemCompanyId }, payload) => request.get(`/system-companies/${SystemCompanyId}/lead-steps`, payload),
    getLeads: ({ SystemCompanyId }, payload) => request.get(`/system-companies/${SystemCompanyId}/leads`, payload),
    deleteLead: ({ SystemCompanyId, leadId }, payload) => request.delete(`/system-companies/${SystemCompanyId}/leads/${leadId}`, payload),
    getLeadSteps: ({ SystemCompanyId },payload) => request.get(`/system-companies/${SystemCompanyId}/lead-steps`, payload),
    postLeadStep: ({ SystemCompanyId },payload) => request.post(`/system-companies/${SystemCompanyId}/lead-steps`, payload),
    putLeadStep: ({ SystemCompanyId, leadStepId },payload) => request.put(`/system-companies/${SystemCompanyId}/lead-steps/${leadStepId}`, payload),
    deleteLeadStep: ({ SystemCompanyId, stepId },payload) => request.delete(`/system-companies/${SystemCompanyId}/lead-steps/${stepId}`, payload),
    postNote: (payload) => request.post('/api/notes', payload),
    postTask: (payload) => request.post('/api/tasks', payload),
    getNotes: ({ SystemCompanyId, leadId },payload) => request.get(`/system-companies/${SystemCompanyId}/notes/${leadId}`, payload),
    postNotes: ({ SystemCompanyId },payload) => request.post(`/system-companies/${SystemCompanyId}/notes`, payload),
    getTasks: ({ SystemCompanyId, leadId },payload) => request.get(`/system-companies/${SystemCompanyId}/tasks/${leadId}`, payload),
    postTasks: ({ SystemCompanyId },payload) => request.post(`/system-companies/${SystemCompanyId}/tasks`, payload),
    getContacts: ({ SystemCompanyId },payload) => request.get(`/system-companies/${SystemCompanyId}/contacts`, payload),
    putContact: ({ SystemCompanyId, contactId },payload) => request.put(`/system-companies/${SystemCompanyId}/contacts/${contactId}`, payload),
    deleteContact: ({ SystemCompanyId, deleteId },payload) => request.delete(`/system-companies/${SystemCompanyId}/contacts/${deleteId}`, payload),
    getCompanies: ({ SystemCompanyId },payload) => request.get(`/system-companies/${SystemCompanyId}/contact-companies`, payload),
    putCompanies: ({ SystemCompanyId, companyId },payload) => request.put(`/system-companies/${SystemCompanyId}/contact-companies/${companyId}`, payload),
    deleteCompanies: ({ SystemCompanyId, deleteId },payload) => request.delete(`/system-companies/${SystemCompanyId}/contact-companies/${deleteId}`, payload),
    getProfile: ({ SystemCompanyId, ResponsibleUserId },payload) => request.get(`/system-companies/${SystemCompanyId}/profiles/${ResponsibleUserId}`, payload),
    putProfilePwd: ({ SystemCompanyId, ResponsibleUserId },payload) => request.put(`/system-companies/${SystemCompanyId}/profiles/${ResponsibleUserId}/change-password`, payload),
    putProfileInfo: ({ SystemCompanyId, ResponsibleUserId },payload) => request.put(`/system-companies/${SystemCompanyId}/profiles/${ResponsibleUserId}/update`, payload),
    getUsers: ({ SystemCompanyId },payload) => request.get(`/system-companies/${SystemCompanyId}/system-company-users`, payload),
    addUsers: ({ SystemCompanyId },payload) => request.post(`/system-companies/${SystemCompanyId}/system-company-users`, payload),
    DeleteUsers: ({ SystemCompanyId, deleteId },payload) => request.delete(`/system-companies/${SystemCompanyId}/system-company-users/${deleteId}`, payload),
    editUsers: ({ SystemCompanyId, UserId },payload) => request.put(`/system-companies/${SystemCompanyId}/system-company-users/${UserId}`, payload),
    editUsersPassword: ({ SystemCompanyId, UserId },payload) => request.put(`/system-companies/${SystemCompanyId}/system-company-users/${UserId}/change-password`, payload),
    getChannels: ({ SystemCompanyId },payload) => request.get(`/system-companies/${SystemCompanyId}/channels/preview`, payload),
    putChannel: ({ SystemCompanyId, channelId },payload) => request.put(`/system-companies/${SystemCompanyId}/channels/${channelId}`, payload),
    addLeadChannels: ({ SystemCompanyId }, payload) => request.post(`/system-companies/${SystemCompanyId}/lead-channels`, payload),
    getLeadChannels: ({ SystemCompanyId }, payload) => request.get(`/system-companies/${SystemCompanyId}/lead-channels`, payload),
    putLeadChannels: ({ SystemCompanyId, leadChannelId }, payload) => request.put(`/system-companies/${SystemCompanyId}/lead-channels/${leadChannelId}`, payload),
    deleteLeadChannels: ({ SystemCompanyId, leadChannelId }, payload) => request.delete(`/system-companies/${SystemCompanyId}/lead-channels/${leadChannelId}`, payload),
    getCompanyInfo: ({ SystemCompanyId },payload) => request.get(`/system-companies/${SystemCompanyId}/companies`, payload),
    putCompanyInfo: ({ SystemCompanyId },payload) => request.put(`/system-companies/${SystemCompanyId}/companies`, payload),
}