import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';

i18next
    .use(initReactI18next)
    .use(LanguageDetector)
    .use(Backend)
    .init({
        fallbackLng: 'en',
        resources: {
            en: {
                translation: {
                    Language: 'Language',
                    /* Login Page */
                    LoginTitle: 'Login',
                    LoginBtn: 'Login',
                    Email: 'Email',
                    CompanyName: 'Company Name',
                    Password: 'Password',
                    ForgotPassword: "Forgot Password?",
                    NotMember: 'Not a Member?',
                    CreateAnAccount: 'Create an Account',
                    /* Register Page */
                    RegisterTitle: 'Registration',
                    Name: 'Name',
                    Surname: 'Surname',
                    AgreeThe: 'Agree the',
                    TermsPolicy: 'terms and policy',
                    RegisterBtn: 'Register',
                    AlreadyAccount: 'Already have account?',
                    SignIn: 'Sign In'
                }
            },
            tr: {
                translation: {
                    Language: 'Dil',
                    /* Login Page */
                    LoginTitle: 'Giriş',
                    LoginBtn: 'Giriş Yap',
                    Email: 'Eposta',
                    CompanyName: 'Şirket Adı',
                    Password: 'Şifre',
                    ForgotPassword: "Şifreni mi Unuttun?",
                    NotMember: 'Üyeliğin yok mu?',
                    CreateAnAccount: 'Hesap Oluştur',
                    /* Register Page */
                    RegisterTitle: 'Kayıt',
                    Name: 'İsim',
                    Surname: 'Soyisim',
                    AgreeThe: 'Kabul et',
                    TermsPolicy: 'şartlar ve politika',
                    RegisterBtn: 'Kayıt Ol',
                    AlreadyAccount: 'Zaten Hesabınız var mı?',
                    SignIn: 'Giriş Yap'
                }
            }
        }
    })