import { createSlice } from "@reduxjs/toolkit";

const listSlice = createSlice({
    name: 'lists',
    initialState: {
        trigger: false,
        /* Company List */
        companies: [],
        /* Company Edit */
        editCompanyName: '',
        editCompanyPhone: '',
        editCompanyEmail: '',
        companyId: ''
    },
    reducers: {
        setTrigger: (state, action) => {
            state.trigger = action.payload.trigger;
        },
        /* Company List */
        setCompanies: (state, action) => {
            state.companies = action.payload.companies;
        },
        /* Company Edit */
        setEditCompanyName: (state, action) => {
            state.editCompanyName = action.payload.editCompanyName;
        },
        setEditCompanyPhone: (state, action) => {
            state.editCompanyPhone = action.payload.editCompanyPhone;
        },
        setEditCompanyEmail: (state, action) => {
            state.editCompanyEmail = action.payload.editCompanyEmail;
        },
        setCompanyId: (state, action) => {
            state.companyId = action.payload.companyId;
        },
    }
})

export const { setTrigger, setCompanies, setEditCompanyName, setEditCompanyPhone, setEditCompanyEmail, setCompanyId } = listSlice.actions;

const listReducer = listSlice.reducer;
export default listReducer;