import React from 'react'
import { Breadcrumb, Card, Col, Form, FormGroup, Row } from 'react-bootstrap'
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2';
import { setCompanyName, setCompanyTrigger } from '../../redux/features/company.slice';
import { ApiRequest } from '../../api';

function CompanyEdit() {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { companyName, companyTrigger } = useSelector(state => state.company);

    /*====================== SWEETALERT START ======================*/
    const Toast = Swal.mixin({
        toast: true,
        position: 'bottom-end',
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })
    /*====================== SWEETALERT END ======================*/

    /*======== POST COMPANY INFO AREA START ========*/
    const postCompanyData = (e) => {
        e.preventDefault();
        ApiRequest.putCompanyInfo({ SystemCompanyId: localStorage.getItem("SystemCompanyId") }, {
            "companyName": companyName
        })
        .then(response => {
            Toast.fire({
                icon: 'success',
                title: 'Başarıyla eklendi!'
            })
            dispatch(setCompanyTrigger({ companyTrigger: !companyTrigger }));
            navigate(-1)
        })
        .catch(error => {
            console.log(error)
        })
    }
    // console.log(companyName);
    /*======== POST COMPANY INFO AREA END ========*/

    const comeBack = () => {
        navigate(-1)
    }

    return (
        <div>
            <div className="page-header">
                <div>
                    <h1 className="page-title">Firma Bilgileri Düzenle</h1>
                    <Breadcrumb className="breadcrumb">
                        <Breadcrumb.Item className="breadcrumb-item" href="#">
                            Firma Bilgileri
                        </Breadcrumb.Item>
                        <Breadcrumb.Item
                            className="breadcrumb-item active breadcrumds"
                            aria-current="page"
                        >
                            Firma Bilgileri Düzenle
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </div>
            </div>

            <div>
                <Card>
                    <Card.Header>
                        <Card.Title as="h3">Firma Bilgileri</Card.Title>
                    </Card.Header>
                    <Card.Body>
                        <Row>
                            <Col lg={6} md={12}>
                                <FormGroup>
                                    <label htmlFor="exampleInputname">Firma Adı</label>
                                    <Form.Control
                                        type="text"
                                        className="form-control"
                                        id="exampleInputname"
                                        onChange={e => dispatch(setCompanyName({ companyName: e.target.value }))}
                                        defaultValue={companyName}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                    </Card.Body>
                    <Card.Footer className="text-end">
                        <a to="#" onClick={postCompanyData} className="btn btn-primary text-white mt-1 me-2">
                            Kaydet
                        </a>
                        <a href='#' onClick={comeBack} className="btn btn-danger mt-1 me-2">
                            Vazgeç
                        </a>
                    </Card.Footer>
                </Card>
            </div>
        </div>
    )
}

export default CompanyEdit