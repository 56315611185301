import React from 'react'
import { Link, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { setEditChannelName } from '../../redux/features/leadChannels.slice';
import { ApiRequest } from '../../api';
import { Breadcrumb, Button, Card, Col, Form, FormGroup, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

function LeadChannelsUpsert() {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { editChannelName , editChannelId } = useSelector(state => state.leadChannels)

    /*====================== SWEETALERT START ======================*/
    const Toast = Swal.mixin({
        toast: true,
        position: 'bottom-end',
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })
    /*====================== SWEETALERT END ======================*/
    /*====================== EDIT CHANNELS START ======================*/
    const editChannels = () => {
        ApiRequest.putLeadChannels({ SystemCompanyId: localStorage.getItem("SystemCompanyId") , leadChannelId: editChannelId } , {
            "channelName": editChannelName
        })
            .then(response => {
                Toast.fire({
                    icon: 'success',
                    title: 'Başarıyla düzenlendi!'
                })
            })
            .catch(error => {
                console.log(error)
                Toast.fire({
                    icon: 'error',
                    title: 'Başarıyla düzenlenemedi!'
                })
            })
            .finally(function () { navigate(-1); })
    }
    /*====================== EDIT CHANNELS END ======================*/

    /* POST İŞLEMİ SONRASI İNPUTLARI TEMİZLEMEK */
    const handleSubmitChannel = async (e) => {
        e.preventDefault();
    }

    const comeBack = () => {
        navigate(-1)
    }

  return (
    <div>
            <div className="page-header">
                <div>
                    <h1 className="page-title">Kanal Düzenle</h1>
                    <Breadcrumb className="breadcrumb">
                        <Breadcrumb.Item className="breadcrumb-item" href="#">
                            Anasayfa
                        </Breadcrumb.Item>
                        <Breadcrumb.Item
                            className="breadcrumb-item active breadcrumds"
                            aria-current="page"
                        >
                            Kanal Düzenle
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </div>
            </div>

            <div>
                <Form onSubmit={handleSubmitChannel}>
                    <Card>
                        <Card.Header>
                            <Card.Title as="h3">Kademe Bilgileri Güncelle</Card.Title>
                        </Card.Header>
                        <Card.Body>
                            <Row>
                                <Col lg={6} md={12}>
                                    <FormGroup>
                                        <label htmlFor="exampleInputname">Kanal Adı</label>
                                        <Form.Control
                                            type="text"
                                            className="form-control"
                                            id="exampleInputname"
                                            placeholder="Kanal Adı"
                                            onChange={(e) => dispatch(setEditChannelName({ editChannelName: e.target.value }))}
                                            defaultValue={editChannelName}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Card.Body>
                        <Card.Footer className="text-end">
                            <Button type="submit" onClick={editChannels} className="btn btn-success text-white mt-1 me-2">
                                Kaydet
                            </Button>
                            <Link to="#" onClick={comeBack} className="btn btn-danger mt-1 me-2">
                                Vazgeç
                            </Link>
                        </Card.Footer>
                    </Card>
                </Form>
            </div>
        </div>
  )
}

export default LeadChannelsUpsert