import React, { useEffect } from 'react'
import { Breadcrumb, Card, Col, Form, FormGroup, Row, Table } from 'react-bootstrap'
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { setEditStepName, setLeadStepId, setLeadTrigger, setSteps } from '../../redux/features/leadStep.slice';
import { ApiRequest } from '../../api';

const Status = [
    {
        title: 'Todo',
        value: 0
    },
    {
        title: 'In Progress',
        value: 1
    },
    {
        title: 'Done',
        value: 2
    },
]

function LeadStep() {

    const MySwal = withReactContent(Swal)
    const dispatch = useDispatch();
    const { steps, leadTrigger } = useSelector(state => state.leadStep);

    /*====================== SWEETALERT START ======================*/
    const Toast = Swal.mixin({
        toast: true,
        position: 'bottom-end',
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })
    /*====================== SWEETALERT END ======================*/

    /*======== GET LEAD STEP AREA START ========*/
    useEffect(() => {
        ApiRequest.getLeadSteps({ SystemCompanyId: localStorage.getItem("SystemCompanyId") })
            .then(response => {
                dispatch(setSteps({ steps: response }))
            })
            .catch(error => {
                console.log(error);
            })
    }, [leadTrigger]);
    /*======== GET LEAD STEP AREA END ========*/

    /*======== DELETE LEAD STEP DELETE AREA START ========*/
    const stepDelete = (id) => {
        console.log(id)
        ApiRequest.deleteLeadStep({ SystemCompanyId: localStorage.getItem("SystemCompanyId"), stepId: id })
            .then(response => {
                Toast.fire({
                    icon: 'success',
                    title: 'Başarıyla Silindi!'
                })
                dispatch(setLeadTrigger({ leadTrigger: !leadTrigger }))
            })
            .catch(error => { console.log(error) })
    }

    const confirmDelete = (id) => {
        MySwal.fire({
            title: <strong>Silmek istediğinize emin misiniz?</strong>,
            icon: 'question',
            showCloseButton: true,
            showCancelButton: true,
            confirmButtonText: <button className='btn btn-success custom-btn' onClick={() => { stepDelete(id) }}>Evet</button>,
            cancelButtonText: <button className='btn btn-danger custom-btn'>Hayır</button>
        })
    }
    /*======== DELETE LEAD STEP DELETE AREA END ========*/


    return (
        <div>
            <div className="page-header">
                <div>
                    <h1 className="page-title">Lead Kademeleri</h1>
                    <Breadcrumb className="breadcrumb">
                        <Breadcrumb.Item className="breadcrumb-item" href="#">
                            Anasayfa
                        </Breadcrumb.Item>
                        <Breadcrumb.Item
                            className="breadcrumb-item active breadcrumds"
                            aria-current="page"
                        >
                            Lead Kademeleri
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </div>
                <div className="ms-auto pageheader-btn">
                    <Link to={"/LeadStep/Add"} className="btn btn-primary btn-icon text-white me-3">
                        <span>
                            <i className="fe fe-plus"></i>&nbsp;
                        </span>
                        Ekle
                    </Link>
                </div>
            </div>

            <Row className=" row-sm">
                <Col lg={12}>
                    <Card>
                        <Card.Header>
                            <h3 className="card-title">Kademe Listesi</h3>
                        </Card.Header>
                        <Card.Body>
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Kademe Adı</th>
                                        <th>Kademe Durumu</th>
                                        <th>Düzenle</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {steps.map((item, index) => (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td className="text-capitalize">{item.stepName}</td>
                                            <td className="text-capitalize">{item.stepStatus}</td>
                                            <td>
                                                <Link
                                                    to={`/LeadStep/Upsert`}
                                                    className="btn btn-primary me-2"
                                                    onClick={() => {
                                                        dispatch(setEditStepName({ editStepName: item.stepName }));
                                                        dispatch(setLeadStepId({ leadStepId: item.leadStepId }));
                                                    }}
                                                >
                                                    <i className="fa fa-edit me-1"></i>
                                                    <span>Düzenle</span>
                                                </Link>
                                                <a href="#" onClick={() => { confirmDelete(item.leadStepId) }} className="btn btn-danger">
                                                    <i className="fa fa-trash me-1"></i>
                                                    Sil
                                                </a>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

        </div>
    )
}

export default LeadStep