import React from 'react'
import { Breadcrumb, Button, Card, Col, Form, FormGroup, Row } from 'react-bootstrap'
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2';
import { setEditStepName, setEditStepStatus, setLeadStepId, setLeadTrigger, setStepName, setStepStatus } from '../../redux/features/leadStep.slice';
import { ApiRequest } from '../../api';

const Status = [
    {
        title: 'Todo',
        value: 0
    },
    {
        title: 'In Progress',
        value: 1
    },
    {
        title: 'Done',
        value: 2
    },
]

function LeadStepAdd() {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { steps, stepName, stepStatus, leadTrigger } = useSelector(state => state.leadStep);

    /*====================== SWEETALERT START ======================*/
    const Toast = Swal.mixin({
        toast: true,
        position: 'bottom-end',
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })
    /*====================== SWEETALERT END ======================*/

    /*======== POST LEAD STEP AREA START ========*/
    const postLeadStep = () => {
        ApiRequest.postLeadStep({ SystemCompanyId: localStorage.getItem("SystemCompanyId") }, {
            "stepName": stepName,
            "stepOrder": 0,
            "stepStatus": JSON.parse(stepStatus),
            "isDeleted": false
        })
            .then(response => {
                Toast.fire({
                    icon: 'success',
                    title: 'Başarıyla eklendi!'
                })
                dispatch(setLeadTrigger({ leadTrigger: !leadTrigger }))
            })
            .catch(error => { console.log(error) })
            .finally(navigate(-1))
    }
    /*======== POST LEAD STEP AREA END ========*/

    // console.log("steps :", steps)

    /* POST İŞLEMİ SONRASI İNPUTLARI TEMİZLEMEK */
    const handleSubmitStep = async (e) => {
        e.preventDefault();
    }

    const comeBack = () => {
        navigate(-1)
    }

    return (
        <div>
            <div className="page-header">
                <div>
                    <h1 className="page-title">Kademe Ekle</h1>
                    <Breadcrumb className="breadcrumb">
                        <Breadcrumb.Item className="breadcrumb-item" href="#">
                            Anasayfa
                        </Breadcrumb.Item>
                        <Breadcrumb.Item
                            className="breadcrumb-item active breadcrumds"
                            aria-current="page"
                        >
                            Kademe Ekle
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </div>
            </div>

            <div>
                <Card>
                    <Card.Header>
                        <Card.Title as="h3">Kademe Ekle</Card.Title>
                    </Card.Header>
                    <Card.Body>
                        <Row>
                            <Col lg={6} md={12}>
                                <FormGroup>
                                    <label htmlFor="exampleInputname">Kademe Adı</label>
                                    <Form.Control
                                        type="text"
                                        className="form-control"
                                        id="exampleInputname"
                                        placeholder='Step adı giriniz..'
                                        onChange={(e) => dispatch(setStepName({ stepName: e.target.value }))}
                                    />
                                </FormGroup>
                            </Col>
                            <Col lg={6} md={12}>
                                <div>
                                    <label htmlFor="exampleInputname">Kademe Durumu</label>
                                    <select className='form-select' name="cars" id="cars" defaultValue='default' onChange={(e) => dispatch(setStepStatus({ stepStatus: e.target.value }))}>
                                        <option value='default' disabled>Seçiniz..</option>
                                        {Status.map((item, index) => (
                                            <option value={item.value} key={index}>{item.title}</option>
                                        ))}
                                    </select>
                                </div>
                            </Col>
                        </Row>
                    </Card.Body>
                    <Card.Footer className="text-end">
                        <a to="#" onClick={postLeadStep} className="btn btn-primary text-white mt-1 me-2">
                            Kaydet
                        </a>
                        <a href='#' onClick={comeBack} className="btn btn-danger mt-1 me-2">
                            Vazgeç
                        </a>
                    </Card.Footer>
                </Card>
            </div>
        </div>
    )
}

export default LeadStepAdd