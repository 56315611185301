import React, { useEffect } from 'react'
import { Breadcrumb, Button, Card, Col, Form, FormGroup, Row } from 'react-bootstrap'
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2';
import { setEditStepName, setEditStepStatus, setLeadStepId, setLeadTrigger } from '../../redux/features/leadStep.slice';
import { ApiRequest } from '../../api';

const Status = [
    {
        title: 'Todo',
        value: 0
    },
    {
        title: 'In Progress',
        value: 1
    },
    {
        title: 'Done',
        value: 2
    },
]

function LeadStepUpsert() {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { editStepName, editStepStatus, leadStepId, leadTrigger } = useSelector(state => state.leadStep)

    /*====================== SWEETALERT START ======================*/
    const Toast = Swal.mixin({
        toast: true,
        position: 'bottom-end',
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })
    /*====================== SWEETALERT END ======================*/
    /*====================== EDIT STEP START ======================*/
    const editSteps = () => {
        ApiRequest.putLeadStep({ SystemCompanyId: localStorage.getItem("SystemCompanyId") , leadStepId: leadStepId}, {
            "stepName": editStepName,
            "stepOrder": 0,
            "stepStatus": JSON.parse(editStepStatus),
            "isDeleted": false
        })
            .then(response => {
                Toast.fire({
                    icon: 'success',
                    title: 'Başarıyla düzenlendi!'
                })
                dispatch(setLeadTrigger({ leadTrigger: !leadTrigger }))
            })
            .catch(error => {
                console.log(error)
                Toast.fire({
                    icon: 'error',
                    title: 'Başarıyla düzenlenemedi!'
                })
            })
            .finally(function () { navigate(-1); })
    }
    /*====================== EDIT STEP END ======================*/

    /* POST İŞLEMİ SONRASI İNPUTLARI TEMİZLEMEK */
    const handleSubmitStep = async (e) => {
        e.preventDefault();
    }

    const comeBack = () => {
        navigate(-1)
    }

    return (
        <div>
            <div className="page-header">
                <div>
                    <h1 className="page-title">Kademe Düzenle</h1>
                    <Breadcrumb className="breadcrumb">
                        <Breadcrumb.Item className="breadcrumb-item" href="#">
                            Anasayfa
                        </Breadcrumb.Item>
                        <Breadcrumb.Item
                            className="breadcrumb-item active breadcrumds"
                            aria-current="page"
                        >
                            Kademe Düzenle
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </div>
            </div>

            <div>
                <Form onSubmit={handleSubmitStep}>
                    <Card>
                        <Card.Header>
                            <Card.Title as="h3">Kademe Bilgileri Güncelle</Card.Title>
                        </Card.Header>
                        <Card.Body>
                            <Row>
                                <Col lg={6} md={12}>
                                    <FormGroup>
                                        <label htmlFor="exampleInputname">Kademe Adı</label>
                                        <Form.Control
                                            type="text"
                                            className="form-control"
                                            id="exampleInputname"
                                            placeholder="İsim Soyisim"
                                            onChange={(e) => dispatch(setEditStepName({ editStepName: e.target.value }))}
                                            defaultValue={editStepName}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col lg={6} md={12}>
                                    <div>
                                        <label htmlFor="exampleInputname">Kademe Durumu</label>
                                        <select className='form-select' name="cars" id="cars" defaultValue='default' onChange={(e) => dispatch(setEditStepStatus({ editStepStatus: e.target.value }))}>
                                            <option value='default' disabled>Seçiniz..</option>
                                            {Status.map((item, index) => (
                                                <option value={item.value} key={index}>{item.title}</option>
                                            ))}
                                        </select>
                                    </div>
                                </Col>
                            </Row>
                        </Card.Body>
                        <Card.Footer className="text-end">
                            <Button type="submit" onClick={editSteps} className="btn btn-success text-white mt-1 me-2">
                                Kaydet
                            </Button>
                            <Link to="#" onClick={comeBack} className="btn btn-danger mt-1 me-2">
                                Vazgeç
                            </Link>
                        </Card.Footer>
                    </Card>
                </Form>
            </div>
        </div>
    )
}

export default LeadStepUpsert