import { createSlice } from "@reduxjs/toolkit";

const companySlice = createSlice({
    name: 'company',
    initialState: {
        companyName: '',
        companyTrigger: false,
    },
    reducers: {
        setCompanyName: (state, action) => {
            state.companyName = action.payload.companyName;
        },
        setCompanyTrigger: (state, action) => {
            state.companyTrigger = action.payload.companyTrigger;
        },
    }
})

export const { setCompanyName, setCompanyTrigger } = companySlice.actions;

const companyReducer = companySlice.reducer;
export default companyReducer;