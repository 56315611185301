import React from 'react'
import { Breadcrumb, Card, Col, Form, FormGroup, Row } from 'react-bootstrap'
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2';
import { setCompanyId, setEditCompanyEmail, setEditCompanyName, setEditCompanyPhone, setTrigger } from '../../redux/features/list.slice';
import { ApiRequest } from '../../api';

function CompanyListEdit() {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { trigger, editCompanyName, editCompanyPhone, editCompanyEmail, companyId } = useSelector(state => state.lists);

    /*====================== SWEETALERT START ======================*/
    const Toast = Swal.mixin({
        toast: true,
        position: 'bottom-end',
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })
    /*====================== SWEETALERT END ======================*/

    /*======== POST CONTACT INFO AREA START ========*/
    const postCompanyData = (e) => {
        e.preventDefault();
        ApiRequest.putCompanies({ SystemCompanyId: localStorage.getItem("SystemCompanyId"), companyId: companyId }, {
            "contactCompanyName": editCompanyName,
            "workPhone": editCompanyPhone,
            "workEmail": editCompanyEmail
        })
            .then(response => {
                Toast.fire({
                    icon: 'success',
                    title: 'Başarıyla eklendi!'
                })
                dispatch(setTrigger({ trigger: !trigger }));
                navigate(-1)
            })
            .catch(error => { console.log(error) })
    }
    /*======== POST CONTACT INFO AREA END ========*/

    const comeBack = () => {
        navigate(-1)
    }

    return (
        <div>
            <div className="page-header">
                <div>
                    <h1 className="page-title">Şirket Bilgileri Düzenle</h1>
                    <Breadcrumb className="breadcrumb">
                        <Breadcrumb.Item className="breadcrumb-item" href="#">
                            Şirket Listesi
                        </Breadcrumb.Item>
                        <Breadcrumb.Item
                            className="breadcrumb-item active breadcrumds"
                            aria-current="page"
                        >
                            Şirket Bilgileri Düzenle
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </div>
            </div>

            <div>
                <Card>
                    <Card.Header>
                        <Card.Title as="h3">Şirket Bilgileri</Card.Title>
                    </Card.Header>
                    <Card.Body>
                        <Row className='mb-3'>
                            <Col lg={6} md={12}>
                                <FormGroup>
                                    <label htmlFor="exampleInputCompanyName">Şirket Adı</label>
                                    <Form.Control
                                        type="text"
                                        className="form-control"
                                        id="exampleInputCompanyName"
                                        onChange={(e) => dispatch(setEditCompanyName({ editCompanyName: e.target.value }))}
                                        defaultValue={editCompanyName}
                                    />
                                </FormGroup>
                            </Col>
                            <Col lg={6} md={12}>
                                <FormGroup>
                                    <label htmlFor="exampleInputCompanyEmail">Email</label>
                                    <Form.Control
                                        type="email"
                                        className="form-control"
                                        id="exampleInputCompanyEmail"
                                        onChange={(e) => dispatch(setEditCompanyEmail({ editCompanyEmail: e.target.value }))}
                                        defaultValue={editCompanyEmail}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={6} md={12}>
                                <FormGroup>
                                    <label htmlFor="exampleInputCompanyNumber">Telefon</label>
                                    <Form.Control
                                        type="tel"
                                        onKeyPress={(event) => {
                                            if (!/[0-9]/.test(event.key)) {
                                                event.preventDefault();
                                            }
                                        }}
                                        className="form-control"
                                        id="exampleInputCompanyNumber"
                                        onChange={(e) => dispatch(setEditCompanyPhone({ editCompanyPhone: e.target.value }))}
                                        defaultValue={editCompanyPhone}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                    </Card.Body>
                    <Card.Footer className="text-end">
                        <a to="#" onClick={postCompanyData} className="btn btn-primary text-white mt-1 me-2">
                            Kaydet
                        </a>
                        <a href='#' onClick={comeBack} className="btn btn-danger mt-1 me-2">
                            Vazgeç
                        </a>
                    </Card.Footer>
                </Card>
            </div>
        </div>
    )
}

export default CompanyListEdit